import { z } from "zod";
import { QuizMode } from "../enums/quiz-mode.enum";
import { analyticsDataSchema } from "../modules/analytics/dto/analytics-data.schema";
import { quizContentSchema } from './quiz-content.schema';
import { fixBlockOptionsSchema } from "./fix-block-options.schema";
import { IOptions } from "../interfaces/options.interface";

export const optionsSchema = z.object({
  quizId: z.string(),
  mode: z.nativeEnum(QuizMode),
  autoOpen: z.optional(z.union([z.number().min(0).max(600), z.literal(false)])).default(15),
  autoOpenLimit: z.number().optional().default(1),
  openOnScroll: z.boolean().optional().default(false),
  openOnLeave: z.boolean().default(false),
  host: z.optional(z.string().url("Параметр host должен быть url адресом")),
  customParams: z.optional(z.record(z.any())),
  enableGeolocation: z.boolean().optional().default(false),
  iframe: z.optional(z.string()),
  answerFilter: z.optional(z.union([z.string(), z.number()]).array()),
  questionFilters: z.optional(z.record(z.any())),
  leadExtraParams: z.optional(z.record(z.any())),
  analytics: z.optional(analyticsDataSchema.array()),
  fixBlock: z.optional(z.object({
    active: z.boolean(),
    options: z.optional(fixBlockOptionsSchema)
  })),
  customContent: z.optional(quizContentSchema),
  customSettings: z.optional(z.object({
    theme: z.enum(["light", "dark", "aqua-magenta"]).optional(),
    closeButton: z.boolean().optional(),
    flyingButton: z.boolean().optional(),
    startTestByScroll: z.boolean().optional(),
    whatsAppButtonOnStartPage: z.boolean().optional(),
    whatsAppInfo: z.optional(z.object({
      enabled: z.boolean().optional(),
      phone: z.string(),
      text: z.string().optional(),
      textOnStartPage: z.string().optional(),
      textOnFinalForm: z.string().optional()
    }))
  })),
  test: z.optional(z.boolean())
});

export type IOptionsSchema = z.infer<typeof optionsSchema>;